<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    
  <div class="card">
    <!-- User {{ $route.params.id }} -->
    <div v-for="item in levelofevidence " :key="item.name">
    <p>{{item.journal_name}}</p>
    <h1>{{item.title}}</h1>
    <h3>{{item.author}} , {{item.year}} </h3> 
    <!-- <h4> Cell Source : {{item.cell_source}} , Isolation technique : {{item.isolation_technique}} </h4> 
    <h4> No. of Patient : {{item.no_of_patient}} , Study Type : {{item.study_type}} </h4>  -->
    <br/><br/>

    <div class="container">
  <div class="row">
    <div class="col-sm">
      <strong>Cell Source </strong> <br/>
      {{item.cell_source}} 
    </div>
    <div class="col-sm">
      <strong>Isolation technique</strong> <br/>
      {{item.isolation_technique}}
    </div>
    <div class="col-sm">
      <strong>No. of Patient</strong>  <br/>
      {{item.no_of_patient}}
    </div>
    <div class="col-sm">
      <strong>Study Type </strong> <br/>
      {{item.study_type}}
    </div>
  </div>
</div>
<br/>
<br/>
    
    <p><b>Abstract</b><br/>{{item.summary}}</p>
    
    <button style ="float: right;" @click="new_location(item.link)">Read More</button>
    

    <router-link to="/">Go Back</router-link>

    </div>

    
  </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios';

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
      return {
        evidence: "",
        levelofevidence : []
      };
    },

    async mounted(){
      // console.log("adasdasdasd");
      

      await axios.get('https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_id?id='+this.$route.params.id)
      .then(response => {
        // let res = JSON.parse(response);
        console.log("Level of evidence",response.data.message)

        this.levelofevidence = response.data.message;
       
        console.log(  "this.levelofevidence",this.levelofevidence)

        
      })
      .catch(error => {
        console.log(error)


      })
    
  },

  methods :{

  new_location(link) {
    console.log(link)

    // this.$router.push({path: link});
    window.open(link, '_blank')
  }
  }
}
</script>
