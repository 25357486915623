<template>
  <div class="container">
    <!-- {{ levelofevidences }} -->
    <br />
    <ul
      class="nav navbar-expand-lg nav-pills mb-3"
      id="pills-tab"
      role="tablist"
    >
      <li
        class="nav-item col-sm-2"
        role="presentation"
        v-for="speciality in specialities"
        :key="speciality"
      >
        <button
          class="nav-link"
          id="pills-home-tab"
          data-toggle="pill"
          :data-target="'#' + speciality"
          type="button"
          role="tab"
          :aria-controls="speciality"
          aria-selected="true"
          @click="filterBy(speciality, 'speciality')"
        >
          {{ speciality }}
        </button>
      </li>

      <!-- <li class="nav-item" role="presentation">
    <button class="nav-link" id="pills-contact-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" @click="filterBy('Oncology','speciality')" >Oncology</button>
  </li> -->
    </ul>

    <div class="row">
      <!-- <div class="col-1"></div> -->
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Level of Evidence</a
            >
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                href="#"
                @click="filterBy('Level I', 'level_of_evidence')"
                >Level I</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                @click="filterBy('Level II', 'level_of_evidence')"
                >Level II</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                @click="filterBy('Level III', 'level_of_evidence')"
                >Level III</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                @click="filterBy('Level IV', 'level_of_evidence')"
                >Level IV</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Disease</a
            >
            <div class="dropdown-menu">
              <a
                v-for="topic in topics"
                :key="topic"
                class="dropdown-item"
                href="#"
                @click="filterBy(topic, 'topic')"
                >{{ topic }}</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Type of Study</a
            >
            <div class="dropdown-menu">
              <!-- stydy_types -->
              <a
                v-for="stydy_type in stydy_types"
                :key="stydy_type"
                class="dropdown-item"
                href="#"
                @click="filterBy(stydy_type, 'study_type')"
                >{{ stydy_type }}</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Isolation Technique</a
            >
            <div class="dropdown-menu">
              <a
                v-for="isolation_technique in isolation_techniques"
                :key="isolation_technique"
                class="dropdown-item"
                href="#"
                @click="filterBy(isolation_technique, 'isolation_technique')"
                >{{ isolation_technique }}</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Cell Source</a
            >
            <div class="dropdown-menu">
              <a
                v-for="cell_source in cell_sources"
                :key="cell_source"
                class="dropdown-item"
                href="#"
                @click="filterBy(cell_source, 'cell_source')"
                >{{ cell_source }}</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Authors</a
            >
            <div class="dropdown-menu">
              <a
                v-for="author in authors"
                :key="author"
                class="dropdown-item"
                href="#"
                @click="filterBy(author, 'author')"
                >{{ author }}</a
              >
            </div>
          </li>
          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Titles</a
            >
            <div class="dropdown-menu">
              <!-- titles -->
              <a
                v-for="title in titles"
                :key="title"
                class="dropdown-item"
                href="#"
                @click="filterBy(title, 'title')"
                >{{ title }}</a
              >
            </div>
          </li>

          <li class="nav-item col-sm dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              @click="get_all_reocords"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              >Show All</a
            >
          </li>
        </ul>
      </div>
    </div>

    <br />

    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <table id="myTable" class="table table-striped mt-5">
          <thead>
            <tr>
              <th>Disease</th>
              <th>Title</th>
              <th>Level Of Evidence</th>
              <th>View</th>
              <!-- <th>d</th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <table id="myTable" class="table table-striped mt-5">
          <thead>
            <tr>
              <th>Disease</th>
              <th>Title</th>
              <th>Level Of Evidence</th>
              <th>View</th>
              <!-- <th>d</th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div
        class="tab-pane fade"
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <table id="myTable" class="table table-striped mt-5">
          <thead>
            <tr>
              <th>Disease</th>
              <th>Title</th>
              <th>Level Of Evidence</th>
              <th>View</th>
              <!-- <th>d</th> -->
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "LevelofEvidence",

  data: () => ({
    // users
    specialities: [],
    levelofevidences: [],
    isolation_techniques: [],
    topics: [],
    stydy_types: [],
    tabPaneActiveKey: 1,
    cell_sources: [],
    authors: [],
    titles: [],
  }),

  methods: {
    async filterBy(content, type) {
      console.log("type", type, "content", content);

      if (type == "all") {
        // const post_data = { type: type, content: content };
        // console.log(post_data)

        axios
          .post(
            "https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence"
          )
          .then((response) => {
            console.log(response.data);
            this.update_data_table(response.data.message);
          });
      } else {
        const post_data = { type: type, content: content };

        console.log(post_data);

        axios
          .post(
            "https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content",
            post_data
          )
          .then((response) => {
            console.log(response.data);
            this.update_data_table(response.data.message);
          });
      }

      // if(content == "LevelII"){
      //   const post_data = { type: "level_of_evidence", content: 'level II' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }
      // if(content == "LevelIII"){
      //   const post_data = { type: "level_of_evidence", content: 'level III' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }
      // if(content == "LevelIV"){
      //   const post_data = { type: "level_of_evidence", content: 'level IV' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }
      // // Disease
      // if(content == "Osteoarthritis"){
      //   const post_data = { type: "topic", content: 'Osteoarthritis' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }
      // if(content == "Osteoarthritis"){
      //   const post_data = { type: "topic", content: 'Osteoarthritis' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }

      // // AVN

      // if(content == "Osteoarthritis"){
      //   const post_data = { type: "topic", content: 'AVN' };
      //   axios.post("https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence_by_content", post_data)
      //   .then(response => {
      //     this.update_data_table(response.data.message)
      //   });
      // }
    },

    update_data_table(tabledata) {
      // this.table.destroy();

      $("#myTable").DataTable({
        data: tabledata,
        destroy: true,
        responsive: true,

        columns: [
          { data: "topic" },
          { data: "title" },
          { data: "level_of_evidence" },
          { data: "portal_link" },
          // { data: 'salary' },
          // { data: 'office' }
        ],
      });
    },

    get_all_reocords: async function () {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ title: "Vue POST Request Example" }),
      };
      fetch("https://jsonplaceholder.typicode.com/posts", requestOptions)
        .then((response) => response.json())
        .then((data) => (this.postId = data.id));

      await axios
        .get(
          "https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence"
        )
        .then((response) => {
          this.update_data_table(response.data.message);
          // this.levelofevidences = response.data.message;

          // $('#myTable').DataTable( {

          //           data: ,
          //           destroy: true,
          //           columns: [
          //               { data: 'topic' },
          //               { data: 'title' },
          //               { data: 'level_of_evidence' },
          //               { data: 'portal_link'},
          //               // { data: 'salary' },
          //               // { data: 'office' }
          //           ]
          //       } );

          // let res = JSON.parse(response);
          // console.log("Level of evidence", response.data.message);

          this.levelofevidences = response.data.message;

          // console.log("this.levelofevidence", this.levelofevidences);
          for (let i in this.levelofevidences) {
            // console.log(this.levelofevidences[i].speciality);

            if (
              this.specialities.indexOf(this.levelofevidences[i].speciality) ===
              -1
            ) {
              // this.specialities.push(i.speciality);
              this.specialities.push(this.levelofevidences[i].speciality);
              // console.log(this.specialities);
            }
          }

          // topics

          for (let i in this.levelofevidences) {
            if (this.topics.indexOf(this.levelofevidences[i].topic) === -1) {
              // this.specialities.push(i.speciality);
              this.topics.push(this.levelofevidences[i].topic);
              // console.log(this.specialities);
            }
          }
          // stydy_types
          // console.log(this.levelofevidences);

          for (let i in this.levelofevidences) {
            if (
              this.stydy_types.indexOf(this.levelofevidences[i].study_type) ===
              -1
            ) {
              // this.specialities.push(i.speciality);
              this.stydy_types.push(this.levelofevidences[i].study_type);
              // console.log(this.specialities);
            }
          }

          // isolation_techniques

          for (let i in this.levelofevidences) {
            if (
              this.isolation_techniques.indexOf(
                this.levelofevidences[i].isolation_technique
              ) === -1
            ) {
              // this.specialities.push(i.speciality);
              this.isolation_techniques.push(
                this.levelofevidences[i].isolation_technique
              );
              // console.log(this.specialities);
            }
          }

          // cell_source

          for (let i in this.levelofevidences) {
            if (
              this.cell_sources.indexOf(
                this.levelofevidences[i].cell_source
              ) === -1
            ) {
              // this.specialities.push(i.speciality);
              this.cell_sources.push(this.levelofevidences[i].cell_source);
              // console.log(this.specialities);
            }
          }
          // author

          for (let i in this.levelofevidences) {
            if (this.authors.indexOf(this.levelofevidences[i].author) === -1) {
              // this.specialities.push(i.speciality);
              this.authors.push(this.levelofevidences[i].author);
              // console.log(this.specialities);
            }
          }

          for (let i in this.levelofevidences) {
            if (this.titles.indexOf(this.levelofevidences[i].title) === -1) {
              // this.specialities.push(i.speciality);
              this.titles.push(this.levelofevidences[i].title);
              // console.log(this.specialities);
            }
          }

          // console.log("asas", this.specialities);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  async mounted() {
    // console.log("adasdasdasd");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title: "Vue POST Request Example" }),
    };
    fetch("https://jsonplaceholder.typicode.com/posts", requestOptions)
      .then((response) => response.json())
      .then((data) => (this.postId = data.id));

    await axios
      .get(
        "https://online.sahajhospital.com/api/method/hospital.api.get_levelofevidence"
      )
      .then((response) => {
        this.update_data_table(response.data.message);
        // this.levelofevidences = response.data.message;

        // $('#myTable').DataTable( {

        //           data: ,
        //           destroy: true,
        //           columns: [
        //               { data: 'topic' },
        //               { data: 'title' },
        //               { data: 'level_of_evidence' },
        //               { data: 'portal_link'},
        //               // { data: 'salary' },
        //               // { data: 'office' }
        //           ]
        //       } );

        // let res = JSON.parse(response);
        // console.log("Level of evidence", response.data.message);

        this.levelofevidences = response.data.message;

        // console.log("this.levelofevidence", this.levelofevidences);
        for (let i in this.levelofevidences) {
          // console.log(this.levelofevidences[i].speciality);

          if (
            this.specialities.indexOf(this.levelofevidences[i].speciality) ===
            -1
          ) {
            // this.specialities.push(i.speciality);
            this.specialities.push(this.levelofevidences[i].speciality);
            // console.log(this.specialities);
          }
        }

        // topics

        for (let i in this.levelofevidences) {
          if (this.topics.indexOf(this.levelofevidences[i].topic) === -1) {
            // this.specialities.push(i.speciality);
            this.topics.push(this.levelofevidences[i].topic);
            // console.log(this.specialities);
          }
        }
        // stydy_types
        // console.log(this.levelofevidences);

        for (let i in this.levelofevidences) {
          if (
            this.stydy_types.indexOf(this.levelofevidences[i].study_type) === -1
          ) {
            // this.specialities.push(i.speciality);
            this.stydy_types.push(this.levelofevidences[i].study_type);
            // console.log(this.specialities);
          }
        }

        // isolation_techniques

        for (let i in this.levelofevidences) {
          if (
            this.isolation_techniques.indexOf(
              this.levelofevidences[i].isolation_technique
            ) === -1
          ) {
            // this.specialities.push(i.speciality);
            this.isolation_techniques.push(
              this.levelofevidences[i].isolation_technique
            );
            // console.log(this.specialities);
          }
        }

        // cell_source

        for (let i in this.levelofevidences) {
          if (
            this.cell_sources.indexOf(this.levelofevidences[i].cell_source) ===
            -1
          ) {
            // this.specialities.push(i.speciality);
            this.cell_sources.push(this.levelofevidences[i].cell_source);
            // console.log(this.specialities);
          }
        }
        // author

        for (let i in this.levelofevidences) {
          if (this.authors.indexOf(this.levelofevidences[i].author) === -1) {
            // this.specialities.push(i.speciality);
            this.authors.push(this.levelofevidences[i].author);
            // console.log(this.specialities);
          }
        }

        for (let i in this.levelofevidences) {
          if (this.titles.indexOf(this.levelofevidences[i].title) === -1) {
            // this.specialities.push(i.speciality);
            this.titles.push(this.levelofevidences[i].title);
            // console.log(this.specialities);
          }
        }

        // console.log("asas", this.specialities);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
div.dataTables_wrapper div.dataTables_filter input {
  /* margin-left: 0.5em; */
  display: inline-block;
  /* width: auto; */
}

.nav-pills .nav-link.active {
  background-color: #455f6f;
}

/* .page-item.active .page-link {
  background-color: #455f6f;
    border-color: #455f6f;
} */

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #455f6f;
  border-color: #455f6f;
}
</style>
