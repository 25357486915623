import { createApp } from 'vue'
// import { BootstrapVue } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import "datatables.net-bs4"
import "datatables.net-buttons-bs4"
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css"
import "datatables.net-buttons-bs4/js/buttons.bootstrap4"
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css"
import "datatables.net-buttons-bs4/js/buttons.bootstrap4"
// import 'bootstrap-vue/dist/bootstrap-vue.css'





// import SmartTable from 'vuejs-smart-table'




createApp(App).use(router).mount('#app')
