<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <!-- <nav class="navbar navbar-light" style="background-color: #445f6f;">
    <router-link to="/"><img src="./assets/logo.png"/></router-link> 
    <router-link to="/">Home</router-link>  -->
  <!-- <router-link style ="float: right;"  to="/evidence">All Articales</router-link> -->
  <!-- </nav>  -->
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* background-color: #ffff; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

* {
  /* background-color: #ededed; */
}

.card {
  margin: 100px;
  max-width: 800px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 1rem;
}

.nav-tabs .nav-link {
  color: currentcolor;
}

div.dataTables_wrapper div.dataTables_filter input {
  width: 30rem;
}

/* color: currentcolor; */

/* .navbar {
 */
/* background-color: #445f6f; */

/* background-color: #435f6f;
  max-height: 0px;
  margin-top: -10px;
  margin-left: -10px;
  z-index: 1030;
  color: white;
  
} */

img {
  max-width: 12rem;
}
</style>
